.NotesList__container {

  padding-top: 80px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}
.NotesList__addnote_button {
  margin: 20px 45%;
}

.NotesList__addnote_icon {
  position: sticky;
  bottom: 10px;
  left: 46vw;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  border: 2px solid black;
  padding: 8px;
  color: rgb(0, 55, 128);
  border-radius: 50%;
  font-size: 1.8rem;
  background-color: white;
  cursor: pointer;
}
