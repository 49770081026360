@import url("https://fonts.googleapis.com/css2?family=Signika&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Seymour+One&display=swap");
:root {
  --bgColor: white;
  --fontColor: black;
}

body {
  margin: 0;
  padding: 0;
}
.app {
  overflow-x: none;
  /* width: 99vw; */
  min-height: 100vh;
  transition: 0.3s;
}
