.NotesCard__Container {
  display: flex;
  flex-direction: column;
  width: 60vw;
  border: 2px solid black;
  border-radius: 10px;
  margin: 20px 30px;
  padding: 5px 20px;
}

.NotesCard__title {
  border-bottom: 2px solid black;
  font-family: "Signika", sans-serif;
  width: clamp(100px, 60vw, 780px);
  color: rgb(0, 0, 103);
}
.NotesCard__desc {
  font-family: "Balsamiq Sans", cursive;
  font-size: 1.3rem;
}

.NotesCard__buttons {
  display: flex;
}
.NotesCard__button {
  border: 2px solid black;
  color: var(--fontColor);
  background-color: var(--bgColor);
  aspect-ratio: 1/1;
  margin: 2px 10px;
  padding: 7px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NotesCard__edit_button {
  border: 2px solid black;
  color: var(--fontColor);
  background-color: var(--bgColor);
  aspect-ratio: 1/1;
  margin: 2px 10px;
  padding: 7px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NotesCard__complete {
  text-decoration: line-through;
  text-decoration-thickness:4px ;
}

.NotesCard__complete .NotesCard__edit_button {
  visibility: hidden;
}

@media screen and (min-width: 980px) {
  .NotesCard__Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 60vw;
    border: 2px solid black;
    border-radius: 10px;
    margin: 20px 30px;
    padding: 5px 20px;
  }
  .NotesCard__title {
  border-bottom: none;
  width: 200px;
}
}
