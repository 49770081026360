.NotesForm__page_container {
  width: 99vw;
  height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Balsamiq Sans', cursive;
}

.NotesForm__container {
  background-color: white;
  padding: 20px 30px;

  width: clamp(200px, 80vw, 400px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  border-radius: 30px;
}


.NotesForm__title_input {
  width: clamp(160px, 90%, 300px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  margin-bottom: 15px;
}

.NotesForm__desc_input {
  width: clamp(160px, 90%, 300px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 15px;
}

label{
    font-size: 1.3rem;
    font-weight: 600;
    margin: 2px;
}

input{
    border: 1px solid black;
    height: 20px;
}

.NotesForm__desc_input input{
    height: 60px;
}
.NotesForm__submitBtn{
  margin: 5px 20px;
    padding: 5px 10px;

    width: 90px;

    background-color: rgb(126, 255, 126);
    
    border: 2px solid black;
    border-radius: 20px;

    font-weight: 550;
}

.NotesForm__link{
  text-decoration: none;
  
  font-weight: 600;
  color: black;
}

.NotesForm__close_btn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.NotesForm__close_btn button{
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: white;

}